











import Vue from 'vue';
import ChatContainer from '@/components/Mobile/Chat/ChatContainer.vue';
import Scaffold from '@/components/Mobile/Scaffold.vue';
import { mapGetters, mapMutations } from 'vuex';
export default Vue.extend({
  components: {
    ChatContainer,
    Scaffold,
  },
  mounted() {
    this.$store.dispatch('tour/bindCurrentTour', this.$route.params.tourId);
    this.$store.dispatch('chat/bindChatMessages', this.$route.params.tourId);
  },
  computed: {
    ...mapGetters({
      user: 'auth/getUser',
      tour: 'tour/getCurrentTour',
      messages: 'chat/getMessages',
    }),
  },
  methods: {
    ...mapMutations({
      resetChatMessages: 'chat/RESET',
    }),
  },
  beforeDestroy() {
    this.resetChatMessages();
  },
});

<template>
  <div
    class="flex w-full h-full overflow-y-hidden"
    :class="{ 'pb-20': addBottomPadding }"
  >
    <chat-window
      height="-1"
      :theme="theme"
      :styles="styles"
      :current-user-id="this.user.data.uid"
      :rooms="rooms"
      :loading-rooms="false"
      :messages="messages"
      :messages-loaded="true"
      :message-actions="messageActions"
      :show-files="false"
      :show-audio="false"
      :single-room="true"
      :show-add-room="false"
      :show-new-messages-divider="false"
      :show-reaction-emojis="false"
      :link-options="{ disabled: false, target: '_self' }"
      :text-messages="textMessages"
      @send-message="sendMessage"
      @edit-message="editMessage"
      @delete-message="deleteMessage"
    ></chat-window>
  </div>
</template>

<script>
import ChatWindow from 'vue-advanced-chat';
import { formatShortDate } from '@/utils/date_time';
import 'vue-advanced-chat/dist/vue-advanced-chat.css';
import { isRunningInTheApp } from '@/utils/platform';
export default {
  components: {
    ChatWindow,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    currentTour: {
      type: Object,
      required: true,
    },
    messages: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      loadingRooms: true,
      selectedRoom: null,
      messagesLoaded: false,
      start: null,
      end: null,
      roomsListeners: [],
      listeners: [],
      disableForm: false,
      addNewRoom: null,
      addRoomUsername: '',
      inviteRoomId: null,
      invitedUsername: '',
      removeRoomId: null,
      removeUserId: '',
      removeUsers: [],
      menuActions: [],
      messageActions: [
        {
          name: 'editMessage',
          title: 'Bearbeiten',
          onlyMe: true,
        },
        {
          name: 'deleteMessage',
          title: 'Löschen',
          onlyMe: true,
        },
      ],
      styles: {
        container: { borderRadius: '4px' },
        general: {
          color: '#0a0a0a',
          colorSpinner: '#333',
          borderStyle: '1px solid #e1e4e8',
        },

        footer: {
          background: '#f8f9fa',
          backgroundReply: 'rgba(0, 0, 0, 0.08)',
        },
        icons: {
          search: '#9ca6af',
        },
      },
      theme: 'light',
      textMessages: {
        ROOMS_EMPTY: 'Noch kein Raum',
        ROOM_EMPTY: 'Noch kein Raum',
        NEW_MESSAGES: 'Neue Nachricht',
        MESSAGE_DELETED: 'Die Nachricht wurde gelöscht',
        MESSAGES_EMPTY: '',
        CONVERSATION_STARTED: 'Neue Unterhaltung',
        TYPE_MESSAGE: 'Schreibt...',
        SEARCH: 'Sucht...',
        IS_ONLINE: 'Online',
        LAST_SEEN: 'Zuletzt gesehen am:',
        IS_TYPING: 'Schreibt...',
      },
    };
  },
  computed: {
    rooms() {
      return [
        {
          roomId: this.currentTour.id,
          roomName: this.currentTour.title,
          avatar: this.currentTour.headerImageURI,
          unreadCount: 0,
          index: this.currentTour.id,
          lastMessage: {},
          users: this.chatMembers,
        },
      ];
    },
    chatMembers() {
      const members = [];
      const memberIds = [];
      for (const message of this.messages) {
        if (!memberIds.includes(message.senderId)) {
          members.push({
            _id: message.senderId,
            username: message.username,
            avatar: message.avatar,
          });
          memberIds.push(message.senderId);
        }
      }
      return members;
    },
    addBottomPadding() {
      return !isRunningInTheApp();
    },
  },
  methods: {
    sendMessage({ content }) {
      const message = {
        senderId: this.user.data.uid,
        content,
        createdDate: new Date(),
        userDisplayName: this.user.recordData.username || '',
        avatar:
          this.user.recordData.profileImageURI ||
          'https://firebasestorage.googleapis.com/v0/b/ridee-dev.appspot.com/o/profile-images%2Fridee-logo-round.png?alt=media&token=8ddd6961-99bf-4e68-bf25-31638872228e',
      };
      this.$store.dispatch('chat/sendMessage', {
        message,
        tour: this.currentTour,
        userDisplayName: this.user.recordData.username || '',
      });
    },
    editMessage({ messageId, newContent }) {
      const newMessage = { edited: formatShortDate(new Date()) };
      newMessage.content = newContent;
      this.$store.dispatch('chat/updateMessage', {
        message: newMessage,
        messageId: messageId,
        tourId: this.currentTour.id,
      });
    },
    deleteMessage({ message }) {
      this.$store.dispatch('chat/deleteMessage', {
        messageId: message._id,
        tourId: this.currentTour.id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.window-container {
  width: 100%;
}
</style>
